import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import PayPeriod from "./pages/PayPeriod";
import Register from "./pages/Register";
import TimeClock from "./pages/TimeClock";

function Router() {
    return(
        <Routes className="Routes">
            <Route path="/" element={<Login/>}/>
            <Route path="/register" element={<Register />}/>
            <Route path="/time-clock" element={<TimeClock/>}/>
            <Route path="/pay-period" element={<PayPeriod />}></Route>
            <Route path="*" element={<Navigate to="/" replace/>}/>
        </Routes>
    )
}

export default Router;