import './App.css';
import { BrowserRouter } from "react-router-dom";
import Router from './Router';
function App() {
  return (
    <BrowserRouter className="App">
      <Router />
    </BrowserRouter>
  );
}

export default App;
