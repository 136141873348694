import { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, getEmployeeTimeLogs, getEmployeePayPeriodHours } from "../firebase";
import { where, query, collection, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
function PayPeriod() {

    const [user, loading, error] = useAuthState(auth);
    const [employee, setEmployee] = useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [hours, setHours] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        if(!user) navigate("/");
        if(loading) return;
        const fetchData =  async () => {
            try {
                const q = query(collection(db, "users"), where("email", "==", user?.email));
                const doc = await getDocs(q);
                const data = doc.docs[0].data();
                setEmployee(data);
            } catch(err) {
                console.error(err);
            }
        }

        fetchData();
    }, [user, loading, navigate]);

    const getHours = async (emp, start, end) => {
        const timeLogs = await getEmployeeTimeLogs(emp, start, end);
        const hrs = await getEmployeePayPeriodHours(timeLogs);
        setHours(hrs);
    }

    return (
        <div className="PayPeriod">
            <div className="flex flex-col justify-center p-6">
            <p>Time in</p>
            <input type="datetime-local" onChange={(e) => setStartDate(e.target.value)} />
            <p>Time out</p>
            <input type="datetime-local" onChange={(e) => setEndDate(e.target.value)} />
            </div>
            <h2 className="text-center py-6 text-xl font-bold">{hours} Hours worked</h2>
            
            <div className="text-center pt-6">
            <button className="p-6 border rounded-full px-24 py-2 bg-black text-white" onClick={() => getHours(employee, startDate, endDate)}>Get Hours</button>
            </div>
            
        </div>
    )
}

export default PayPeriod;