import { initializeApp } from "firebase/app";
import { 
    getAuth, 
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
} from "firebase/auth";
import { 
    getFirestore,
    doc,
    query, 
    getDocs, 
    collection, 
    where, 
    addDoc, 
    setDoc,
    updateDoc
} from "firebase/firestore";

import moment from "moment";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID 
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const logInWithEmailAndPassword = async (email, password) => {
    try{
        await signInWithEmailAndPassword(auth, email, password);
    } catch(err) {
        console.error(err);
        alert(err);
    }
};

const registerWithEmailAndPassword = async (firstName, lastName, email, password) => {
    try{
        await createUserWithEmailAndPassword(auth, email, password);
        await addDoc(collection(db, "users"), {
            firstName,
            lastName,
            email,
            admin: false,
            authProvider: "local",
            currentLogId: "",
        });
    } catch(err) {
        console.error(err);
        alert(err);
    }
};

const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link sent!");
    } catch(err) {
        console.error(err);
    }
};

const logout = () => {
    signOut(auth);
};

const getUserData = async (employee) => {
    try{
        // get the reference to the users collection
        const usersRef = await getDocs(collection(db, "users"));
        // filters through the users to get the user that matches employee
        const users = usersRef.docs.filter(doc => doc.data().email === employee.email);
        // format the user document data in a nicer way for js to read, including doc id
        const user = users.map(doc => ({...doc.data(), id : doc.id}));

        return user;
    } catch(err) {
        console.error(err);
        alert(err);
    }
}

/**
 * @param timestamp get hours from this date
 * @param timestamp to hours to this date inclusive
 * 
 * @returns an array containing the time logs between the two dates
 */
const getEmployeeTimeLogs = async (employee, date1, date2) => {
    try{
        const user = await getUserData(employee);   
        // make a reference to the timeLogs collection
        const timeLogsRef = await collection(db, "timeLogs");

        // query for time logs between dat1 and date2 inclusive
        
        const q = query(timeLogsRef, where("timeIn", ">=", new Date(date1)), where("timeIn", "<=", new Date(date2)));
        const querySnapshot = await getDocs(q);

        // create the empty time logs array to return
        let timeLogs = [];

        // fill the timeLogs array with result data
        querySnapshot.forEach(doc => {
            timeLogs.push({...doc.data(), id:doc.id});
        });

        timeLogs = timeLogs.filter(log => log.userId.id === user[0].id);
        // return the time logs queried
        return timeLogs;
    } catch(err) {
        console.error(err);
        alert(err);
    }
}

const calculateTimeLogHours = (date1, date2) => {
    if(!date1 || !date2) return 0;
    const start = moment(date1);
    const end = moment(date2);
    const diff = start.diff(end, "seconds");

    const hours = parseFloat(Math.abs(diff/60/60).toFixed(3));

    // TODO this can be another function another time
    // const timeLogTimes = {
    //     hours: parseInt(diff/60/60),
    //     minutes: diff/60%60
    // }

    return hours;
}

const getEmployeePayPeriodHours = async (timeLogs) => {
    try {
        let hours = 0;
        timeLogs.forEach(log => {
            let hoursToAdd = calculateTimeLogHours(log.timeIn.toDate()||0, log.timeOut.toDate()||0);
            hours += hoursToAdd;
        });

        return parseFloat(hours.toFixed(3));
    } catch (err) {
        console.error(err);
        alert(err);
    }
}


const clockIn = async (employee) => {
    try{
        // gets the user's data
        const user = await getUserData(employee);

        // add a new document to timeLogs with current date timestamp 
        // and a reference to the user that clocked in
        const timeLogRef = await addDoc(collection(db, "timeLogs"), {
            timeIn: new Date(),
            userId: doc(db, "users", user[0].id)
        });

        // update the users document to reflect that he is clocked in and 
        // has a current timeLog id
        await updateDoc(doc(db, "users", user[0].id), {
            currentLogId: timeLogRef.id
        });
    } catch(err) {
        console.error(err);
        alert(err);
    }
}

const clockOut = async (employee) => {
    try {
        // get user data
        const user = await getUserData(employee);

        // get a refernce to the time log
        const timeLogRef = await doc(db, "timeLogs", user[0].currentLogId);

        // set the clock out time to the current date/time
        await updateDoc(timeLogRef, {
            timeOut: new Date()
        });

        // update the user so the time log reference is removed
        await updateDoc(doc(db, "users", user[0].id), {
            currentLogId: ""
        });
    } catch(err) {
        console.error(err);
        alert(err);
    }
}

export {
    auth,
    db,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    clockIn,
    clockOut,
    getEmployeeTimeLogs,
    getEmployeePayPeriodHours
};