import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import FormField from "../components/FormField";
import Logo from "../assets/single-solution-logo.png";
function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, loading, setUser] = useAuthState(auth);
    const navigate = useNavigate();

    const login = (e) => {
        e.preventDefault();
        logInWithEmailAndPassword(email, password);
    }

    useEffect(() => {
        if(loading) {
            <h1>Loading</h1>
            return;
        }

        if(user) navigate("/time-clock");
    }, [user, loading]);

    return(
        <div className="Login mx-auto px-8 lg:w-3/12 md:w-8/12 lg:shadow-xl rounded-lg">
            <img className="mx-auto mt-12 object-scale-down w-60" src={Logo} alt="company logo" />
            <p className="text-center mt-3 mb-12">Proceed with your account</p>
            <form className="">
                <FormField field="email" fieldText="Email" type="text" handleFunc={setEmail} autoFill="email"/>
                <FormField field="password" fieldText="Password" type="password" handleFunc={setPassword} autoFill="current-password"/>
                <div className="text-center pb-12">
                    <button onClick={(e) => login(e)} className="Button border rounded-full px-24 py-2 bg-black text-white">
                        Login
                    </button>
                </div>
            </form>
        </div>
    )
}

export default Login;