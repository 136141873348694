import { useState, useEffect } from "react";
function Time() {
    const [time, setTime] = useState();
    useEffect(() => {
        setInterval(() => {
            const date = new Date();
            setTime(date.toLocaleTimeString());
        }, 1000);
    }, []);
    
    return(
        <div className="Time">
            <h2 className="text-3xl font-bold">{time}</h2>      
        </div>
    );
}

export default Time;