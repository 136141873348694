import { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import {
    auth,
    registerWithEmailAndPassword
} from "../firebase";

import FormField from "../components/FormField";

import Logo from "../assets/single-solution-logo.png";

function Register() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    const register = (e) => {
        e.preventDefault();
        if(!firstName && !lastName && !email && !password) {
            alert("Please fill out all fields");
        }
        registerWithEmailAndPassword(firstName, lastName, email, password);
    }

    useEffect(() => {
        if(loading) return;
        if(user) navigate("/time-clock");
    }, [user, loading]);
    return (
        <div className="Register mx-auto px-8 lg:w-3/12 md:w-8/12 lg:shadow-xl rounded-lg">
            <img className="mx-auto mt-12 object-scale-down w-60" src={Logo} alt="company logo" />
            <p className="text-center mt-3 mb-12">Register an account</p>
            <form>
                <FormField field="email" fieldText="Email" type="text" handleFunc={setEmail} autoFill="email"/>
                <FormField field="firstName" fieldText="First Name" type="text" handleFunc={setFirstName} autoFill="given-name"/>
                <FormField field="lastName" fieldText="Last Name" type="text" handleFunc={setLastName} autoFill="family-name"/>
                <FormField field="password" fieldText="Password" type="password" handleFunc={setPassword} autoFill="current-password"/>
                <div className="text-center pb-12">
                    <button onClick={(e) => register(e)} className="Button border rounded-full px-24 py-2 bg-black text-white">
                        Register User
                    </button>
                </div>
            </form>
        </div>
    )
}

export default Register;