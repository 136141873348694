import React from 'react'

function ActionButton({ clockin, clockout, styling, punchedIn, employee }) {
  return (
    <div className="ActionButton">
        <button className={styling} onClick={punchedIn ? clockout : clockin}>{punchedIn ? "Clock Out" : "Clock In"}</button>
    </div>
  )
}

export default ActionButton