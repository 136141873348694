import { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link } from "react-router-dom";
import { auth, db, logout, clockIn, clockOut } from "../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";

import PageTitle from "../components/PageTitle";
import Time from "../components/Time";
import ActionButton from "../components/ActionButton";

function TimeClock() {
    const [user, loading, error] = useAuthState(auth);
    const [employee, setEmployee] = useState("");
    const [clockedIn, setClockedIn] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        if(loading) return;
        if(!user) return navigate("/");

        const fetchData =  async () => {
            try {
                const q = query(collection(db, "users"), where("email", "==", user?.email));
                const doc = await getDocs(q);
                const data = doc.docs[0].data();
                setEmployee(data);
                setClockedIn(data.currentLogId ? true : false);
            } catch(err) {
                console.error(err);
            }
        }

        fetchData();
    }, [user, loading, clockedIn, navigate]);

    const punchIn = async () => {
        await clockIn(employee);
        setClockedIn(true);
    }

    const punchOut = async () => {
        await clockOut(employee);
        setClockedIn(false);
    }


    return(
        <div className="Home text-center mx-auto">
            <div className="my-12">
                <PageTitle 
                title={`Welcome back, 
                ${employee ? employee.firstName : "user"} 
                ${employee ? employee.lastName : "name"}`}
                subtitle={`You are ${clockedIn ? "Clocked In" : "Clocked Out"}`}
                />
                <Link to="/pay-period">Check hours this period</Link>
            </div>
            <div className="mb-12">
                <Time />
            </div>
            <ActionButton clockin={punchIn} clockout={punchOut} punchedIn={clockedIn} styling={`Button border rounded-full px-24 py-2 
                ${employee.currentLogId ? "bg-red-600" 
                : "bg-green-600"} text-white`} employee={employee}/>
            {/* <button
                onClick={() => employee.currentLogId ? clockOut(employee) : clockIn(employee)} 
                className={
                `Button border rounded-full px-24 py-2 
                ${employee.currentLogId ? "bg-red-600" 
                : "bg-green-600"} text-white`}>
                {employee.currentLogId ? "Clock Out" : "Clock In"}
            </button> <br></br> */}
            <button className={
                `Button border rounded-full px-24 py-2 
                bg-black text-white`} 
                onClick={logout}>Logout
            </button>
        </div>
    );
}

export default TimeClock;